import { useMemo } from 'react';
import { Box, Link, DataTable } from './DataTable.styled';

type ClinicsDataTableProps = {
  data: any;
  orderBy: { id: any; value: any };
  pagination: {
    page: number;
    limit: number;
  };
  onChangePagination: (params: any) => void;
};

export const ClinicsDataTable = (props: ClinicsDataTableProps) => {
  const { data, orderBy } = props;
  const items = useMemo(() => data?.data ?? [], [data]);

  const schema = useMemo(
    () => ({
      columns: [
        {
          id: 'id',
          name: 'ID',
          value: (item: any) => (
            <Link
              to={`/clinics_management/${item.id}?clinicNo=${item.employerNo}&name=${item.name}`}
            >
              {item.id}
            </Link>
          ),
        },

        {
          id: 'status',
          name: '계약상태',
          value: (item: any) => {
            return !(item.status == null || item.status === '') ? (
              item.status === 'active' ? (
                <span>사용중</span>
              ) : (
                <span style={{ color: 'red' }}>해지</span>
              )
            ) : (
              <span style={{ color: 'red' }}>해지</span>
            );
          },
        },
        {
          id: 'name',
          name: '거래처명',
          value: (item: any) => (
            <Link
              to={`/clinics_management/${item.id}?clinicNo=${item.employerNo}&name=${item.name}`}
            >
              {item.name}
            </Link>
          ),
        },
        {
          id: 'employerNo',
          name: '사업자번호',
          value: (item: any) => (
            <Box>{item.employerNo ? item.employerNo : '-'}</Box>
          ),
        },
      ],
    }),
    []
  );

  return (
    <DataTable
      data={items}
      schema={schema}
      sorts={[orderBy]}
      placeholder={'조회 결과가 없습니다.'}
    />
  );
};
