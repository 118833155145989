export type CarrierType = 'KT' | 'LGI' | 'SK';

export const carrierType = {
  KT: 'KT',
  LGI: 'LGI',
  SK: 'SK',

  getName: (carrier: CarrierType) => {
    switch (carrier) {
      case 'KT':
        return 'KT';
      case 'LGI':
        return 'LG';
      case 'SK':
        return 'SK';
      default:
        return '-';
    }
  },
};
