import { useMemo } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import { useApp } from '~/hooks/useApp';
import { banks } from '~/hooks/constants';
import { toThousandCommas } from '~/filters';
import { Tooltip } from '~/components/commons/Tooltip';
import { DataTable } from '~/components/commons/DataTable/DataTable';
import ModalTaxBillConfirm from '~/components/modals/sms/ModalTaxBillConfirm';
import ModalAdminBalanceRefund from '~/components/modals/sms/ModalAdminBalanceRefund';
import ModalEditClinicMemo from '~/components/modals/clinic/ModalEditClinicMemo';
import ModalSmsTotalInfo from '../../modals/sms/ModalSmsTotalInfo';

type SmsTableProps = {
  items: any[];
  callApi: () => void;
};

export const SmsDataTable = observer((props: SmsTableProps) => {
  const { items, callApi } = props;
  const app = useApp();
  const onAddMemo = (memo: any, id: number) => {
    openModalEditClinicMemo(memo, -1, id);
  };

  const onClickMemo = (memo: any, idx: number, id: number) => {
    openModalEditClinicMemo(memo, idx, id);
  };

  const openModalEditClinicMemo = (
    memo: any,
    memoIndex: number,
    id: number
  ) => {
    let clinic: any;

    if (memo == null) {
      clinic = { memo: [], id: id };
    } else {
      clinic = { memo: memo, id: id };
    }

    runInAction(() => {
      app.$modal.custom({
        component: ModalEditClinicMemo,
        options: {
          clinic,
          memoIndex,
          callApi,
        },
      });
    });
  };

  const openModalTaxBillConfirm = (obj: any, confirm: any) => {
    runInAction(() => {
      app.$modal.custom({
        component: ModalTaxBillConfirm,
        options: {
          data: obj,
          confirm,
          callApi,
        },
      });
    });
  };

  const onRefundModal = (item: any) => {
    runInAction(() => {
      app.$modal.custom({
        component: ModalAdminBalanceRefund,
        options: {
          clinicId: item.clinicId,
          data: item,
          callApi: callApi,
        },
      });
    });
  };

  const requestTypeKey: {
    [index: 'deposit' | 'refund' | string]: '충전' | '환불' | undefined;
  } = {
    deposit: '충전',
    refund: '환불',
  };

  const typeKey: {
    [index: 'paid' | 'free' | string]: '유료' | '무료충전' | undefined;
  } = {
    paid: '유료', //유상
    free: '무료충전', //무상
  };

  const statusKey: {
    [index: 'request' | 'complete' | 'canceled' | 'expired' | string]:
      | '신청'
      | '완료'
      | '취소'
      | '충전취소 (입금기한만료)'
      | undefined;
  } = {
    request: '신청',
    complete: '완료', //처리완료
    canceled: '취소',
    expired: '충전취소 (입금기한만료)', //입금기한만료
  };

  const refundDiv = (str: string, item: any) => {
    return (
      <div className="sms-status">
        <div className="text-center">
          <div>{str}</div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <button
              onClick={() => onRefundModal(item)}
              className="btn btn-primary btn-sm btn-refund"
            >
              환불
            </button>
          </div>
        </div>
      </div>
    );
  };

  const statusCombination = (item: any) => {
    if (item.type === 'free') {
      return typeKey[item.type];
    } else {
      if (item.status === 'expired') {
        return statusKey[item.status];
      } else {
        const str = `${requestTypeKey[item.requestType]}${
          statusKey[item.status]
        }`;
        if (item.status === 'request' && item.requestType === 'refund') {
          return refundDiv(str, item);
        } else {
          return `${str}`;
        }
      }
    }
  };

  const onSmsTotalInfo = (data: any) => {
    runInAction(() => {
      app.$modal.custom({
        component: ModalSmsTotalInfo,
        options: {
          totalPaidAmount: data.totalPaidAmount ? data.totalPaidAmount : 0,
          totalFreeAmount: data.totalFreeAmount ? data.totalFreeAmount : 0,
          totalRefundAmount: data.totalRefundAmount
            ? data.totalRefundAmount
            : 0,
          totalPaidCount: data.totalPaidCount ? data.totalPaidCount : 0,
          totalFreeCount: data.totalFreeCount ? data.totalFreeCount : 0,
          paidBalance: data.paidBalance ? data.paidBalance : 0,
          freeBalance: data.freeBalance ? data.freeBalance : 0,
          confirm,
          callApi,
        },
      });
    });
  };

  const schema = useMemo(
    () => ({
      columns: [
        {
          id: 'status',
          name: '상태',
          value: (item: any) => statusCombination(item),
        },
        {
          id: 'requestAt',
          name: '충전 신청일',
          value: (item: any) => {
            return item.requestAt
              ? format(new Date(item.requestAt), 'yyyy-MM-dd HH:mm:ss')
              : '';
          },
        },
        {
          id: 'completeAt',
          name: '입금일',
          value: (item: any) => {
            return item.completeAt
              ? format(new Date(item.completeAt), 'yyyy-MM-dd HH:mm:ss')
              : '';
          },
        },
        {
          id: 'clinicName',
          name: '상호(병원명)',
          value: (item: any) => {
            return (
              <div
                className="td-underline cursor-pointer"
                onClick={() => onSmsTotalInfo(item)}
              >
                {item.clinicName}
              </div>
            );
          },
        },
        {
          id: 'amount',
          name: '신청금액',
          value: (item: any) => {
            return item.amount ? toThousandCommas(item.amount) : '';
          },
        },
        {
          id: 'createdDate',
          name: '입금액(VAT포함)',
          value: (item: any) =>
            item.status === 'complete' && item.type !== 'free'
              ? item.amount
                ? toThousandCommas(String(Math.round(item.amount * 1.1)))
                : ''
              : '',
        },
        {
          id: 'bankCd',
          name: '가상계좌번호',
          value: (item: any) =>
            item.requestType === 'deposit' && item.type !== 'free'
              ? `${
                  item.bankCd !== null
                    ? banks.find((bank: any) => bank.value === item.bankCd)?.key
                    : ''
                } ${item.acctNo || ''} ${item.acctNo || ''}`
              : '',
        },
        {
          id: 'taxBill',
          name: '세금계산서 발행',
          value: (item: any) =>
            item.status === 'complete' ? (
              item.isTaxBill ? (
                <div
                  className="taxbill-date"
                  onClick={() => openModalTaxBillConfirm(item, true)}
                  style={{ cursor: 'pointer' }}
                >
                  {item.taxBillPublishedAt
                    ? format(new Date(item.taxBillPublishedAt), 'yyyy-MM-dd')
                    : ''}
                </div>
              ) : (
                <Tooltip
                  title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}
                >
                  <div
                    style={{
                      display: 'table',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <button
                      onClick={() => openModalTaxBillConfirm(item, false)}
                      className="btn btn-primary btn-sm btn-tax-bill"
                      disabled={app.$me.role === 'manager'}
                    >
                      세금계산서 발행
                    </button>
                  </div>
                </Tooltip>
              )
            ) : (
              ``
            ),
        },
        {
          id: 'refundBankCd',
          name: '환불계좌정보',
          value: (item: any) =>
            item.requestType === 'refund' && item.status === 'complete'
              ? `${
                  item.refundBankCd !== null && item.refundBankCd !== ''
                    ? banks.find(
                        (bank: any) => bank.value === item.refundBankCd
                      )?.key
                    : ''
                } ${item.refundAcctNo || ''} ${item.refundAcctNm || ''}`
              : '',
        },
        {
          id: 'updatedAt',
          name: '최근 상태 변경일',
          value: (item: any) =>
            item.updatedAt
              ? format(new Date(item.updatedAt), 'yyyy-MM-dd HH:mm:ss')
              : '',
        },
        {
          id: 'memo',
          name: 'cs 메모',
          value: (item: any) => (
            <>
              <div style={{ float: 'left' }}>
                {item.memo !== '' && item.memo !== null && item.memo !== '[]'
                  ? JSON.parse(item.memo).map((memoItem: any, idx: number) => {
                      return (
                        <div
                          onClick={() =>
                            onClickMemo(item.memo, idx, item.clinicId)
                          }
                          className="td-underline"
                          key={idx}
                          style={{ cursor: 'pointer' }}
                        >
                          {memoItem.content} ({memoItem.timestamp})
                        </div>
                      );
                    })
                  : null}
              </div>
              <Tooltip
                title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}
              >
                <div
                  style={{
                    display: 'inline-block',
                    float: 'right',
                    verticalAlign: 'middle',
                  }}
                >
                  <button
                    onClick={() => onAddMemo(item.memo, item.clinicId)}
                    className="btn btn-info"
                    disabled={app.$me.role === 'manager'}
                  >
                    추가
                  </button>
                </div>
              </Tooltip>
            </>
          ),
        },
      ],
    }),
    []
  );

  return (
    <DataTable
      data={items}
      schema={schema}
      resizable
      placeholder={'조회 결과가 없습니다.'}
    />
  );
});
