import { Link as _Link } from 'react-router-dom';
import { styled, Box as MuiBox, Button as MuiButton } from '@mui/material';
import { Tooltip as _Tooltip } from '~/components/commons/Tooltip';

export const Link = styled(_Link)`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #2c62f6;
  text-decoration: underline;
`;

export const Button = styled(MuiButton)`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
  height: 24px;
  padding: 4px 6px;
`;

export const Box = styled(MuiBox)(
  ({ theme }) => `
  &.date {
    padding: 0 1rem;
  }
  &.phone-number {
    padding: 0 1rem;
  }
  &.memo {
    width: 200px;
    text-align: left;
  }
  &.memo-btn {
    display: flex;
  }
  &.text-grey {
    color: ${theme.palette.grey['400']}
  }
`
);

export const DisabledButton = styled('button')`
  margin-left: 10px;
  padding: 3px;
  font-weight: 400;
  font-size: 11px;
  border: 1px solid #bbbbbb;
  color: #bbbbbb;
  background-color: #fff;
`;

export const Tooltip = styled(_Tooltip)(({ theme }) => ``);
