import { styled, Box as MuiBox, Stack as MuiStack } from '@mui/material';

export const Box = styled(MuiBox)`
  &.field-wrapper {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    gap: 10px;

    & input::placeholder {
      font-size: 12px;
    }
  }
`;

export const Stack = styled(MuiStack)``;
