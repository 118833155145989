import { Link as _Link } from 'react-router-dom';
import { Box as MuiBox, Button as MuiButton, styled } from '@mui/material';
import { Tooltip as _Tooltip } from '~/components/commons/Tooltip';
import { DataTable as _DataTable } from '~/components/commons/DataTable/DataTable';

export const DataTable = styled(_DataTable)``;

export const Link = styled(_Link)`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #2c62f6;
  text-decoration: underline;
`;

export const Button = styled(MuiButton)`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
  height: 24px;
  padding: 4px 6px;
`;

export const Box = styled(MuiBox)`
  &.date {
    padding: 0 1rem;
  }
  &.phone-number {
    padding: 0 1rem;
  }
  &.memo {
    width: 200px;
    text-align: left;
  }
  &.memo-btn {
    display: flex;
  }
  &.centrex-wrapper {
    span {
      display: block;
    }
  }
`;

export const DisabledButton = styled('button')`
  margin-left: 10px;
  padding: 3px;
  font-weight: 400;
  font-size: 11px;
  border: 1px solid #bbbbbb;
  color: #bbbbbb;
  background-color: #fff;
`;

export const Tooltip = styled(_Tooltip)(() => ``);
