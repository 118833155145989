import { useMemo } from 'react';
import format from 'date-fns/format';
import { DataTable } from '../DataTable.styled';

type ClinicsDataTableProps = {
  data: any;
  orderBy: { id: any; value: any };
  pagination: {
    page: number;
    limit: number;
  };
  onChangePagination: (params: any) => void;
};

export const ClinicsDataTable = (props: ClinicsDataTableProps) => {
  const { data, orderBy } = props;
  const items = useMemo(() => {
    return data?.data || [];
  }, [data]);

  const schema = useMemo(
    () => ({
      columns: [
        {
          id: 'chartNo',
          name: '차트번호',
          value: (item: any) => item.chartNo || '-',
        },
        {
          id: 'id',
          name: '고객번호',
          value: (item: any) => item.id || '-',
        },
        {
          id: 'name',
          name: '고객명',
          value: (item: any) => item.name || '-',
        },
        {
          id: 'createdAt',
          name: '고객등록일',
          value: (item: any) => format(new Date(item.createdAt), 'yyyy-MM-dd'),
        },
      ],
    }),
    []
  );

  return (
    <DataTable
      data={items}
      schema={schema}
      sorts={[orderBy]}
      placeholder={'조회 결과가 없습니다.'}
    />
  );
};
