import { useState } from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { format } from 'date-fns';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { useApp } from '~/hooks/useApp';
import { useApi } from '~/components/providers/ApiProvider';
import { OverlayWrapper } from '~/components/commons/modals/Overlay';
import {
  ModalWrapper,
  Button,
  Box,
  FormControl,
  TextareaAutosize,
} from './ModalDeactivate.styled';

type ModalDeactivateReasonProps = {
  info: { memo: string; memoIndex: number; id: number; item: any };
  onClose: () => void;
  title: string;
};

const ModalDeactivateReason = ({
  info,
  onClose,
  title,
}: ModalDeactivateReasonProps) => {
  const snackbar = useSnackbarContext();
  const app = useApp();
  const { clinicsApi } = useApi();
  const memo = app.$mustParse(info.memo);
  const [currentMemo, setCurrentMemo] = useState(
    (memo && memo[info.memoIndex]) || {
      content: '',
      timestamp: format(new Date(), 'yy-MM-dd'),
    }
  );

  const handleConfirm = () => {
    try {
      const newCurrentMemo = { ...currentMemo };
      if (!newCurrentMemo.timestamp)
        newCurrentMemo.timestamp = format(new Date(), 'yy-MM-dd');
      if (info.memoIndex >= 0) {
        memo[info.memoIndex] = newCurrentMemo;
        info.memo = memo;
      } else {
        info.memo = (memo || []).concat([newCurrentMemo]);
      }
      clinicsApi.memoUpdate(info.id, {
        id: info.id,
        memo: JSON.stringify(info.memo),
      });
      runInAction(() => {
        info.item.memo = JSON.stringify(info.memo);
      });
      snackbar.success('UPDATED');
      onClose();
    } catch (e: any) {
      snackbar.alert(e.description);
    }
  };

  const handleDelete = () => {
    try {
      memo.splice(info.memoIndex, 1);
      clinicsApi.memoUpdate(info.id, {
        id: info.id,
        memo: JSON.stringify(memo),
      });
      snackbar.success('UPDATED');
      runInAction(() => {
        info.item.memo = JSON.stringify(memo);
      });
      onClose();
    } catch (e: any) {
      snackbar.alert(e.description);
    }
  };

  // for development only
  const forceDelete = () => {
    clinicsApi.memoUpdate(info.id, '');
    snackbar.success('UPDATED');
    runInAction(() => {
      info.item.memo = '';
    });
    onClose();
  };

  return (
    <OverlayWrapper onClose={onClose}>
      <ModalWrapper>
        <Box className="modal-header">{title}</Box>
        <Box className="modal-body">
          <FormControl>
            <Box className="form-box w-max">
              <TextareaAutosize
                value={currentMemo.content}
                minRows={3}
                onChange={(e) => {
                  setCurrentMemo({ ...currentMemo, content: e.target.value });
                }}
              />
            </Box>
          </FormControl>
        </Box>
        <Box className="modal-footer">
          <Button variant="contained" className="btn-default" onClick={onClose}>
            취소
          </Button>
          {info.memoIndex >= 0 ? (
            <Button
              variant="contained"
              className="btn-danger"
              onClick={handleDelete}
            >
              삭제
            </Button>
          ) : (
            ''
          )}
          {/* <Button
            variant="contained"
            className="btn-danger"
            onClick={forceDelete}
          >
            강제 삭제
          </Button> */}
          <Button
            variant="contained"
            onClick={handleConfirm}
            disabled={currentMemo.content === ''}
          >
            확인
          </Button>
        </Box>
      </ModalWrapper>
    </OverlayWrapper>
  );
};

export default observer(ModalDeactivateReason);
