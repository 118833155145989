import { useMemo } from 'react';
import { runInAction } from 'mobx';
import { format } from 'date-fns';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { useImperativeModal } from '~/components/providers/ImperativeModalProvider';
import { useApp } from '~/hooks/useApp';
import { agencies } from '~/hooks/constants';
import { carrierType } from '~/utils/carrierTypeUtil';
import { toThousandCommas, maskName, maskPhoneNumber } from '~/filters';
import { theme } from '~/theme';
import ModalDeactivate from './ModalDeactivate';
import ModalEditClinicMemo from './ModalEditClinicMemo';
import { Tooltip } from '~/components/commons/Tooltip';
import { Button } from '~/components/commons/Button';
import { Box, Link, DataTable } from './DataTable.styled';

type ClinicsDataTableProps = {
  data: any;
  orderBy: { id: any; value: any };
  pagination: {
    page: number;
    limit: number;
  };
  onChangePagination: (params: any) => void;
};

export const ClinicsDataTable = (props: ClinicsDataTableProps) => {
  const { data, orderBy } = props;
  const app = useApp();
  const snackbar = useSnackbarContext();
  const imperativeModal = useImperativeModal();
  const items = useMemo(() => data?.data ?? [], [data]);

  const t = (key: any) => app.$translate(key);

  const handleClickMemo = async (
    memo: string,
    memoIndex: number,
    id: number,
    item: any
  ) => {
    const info = { memo, memoIndex, id, item };
    await imperativeModal.open((close) => {
      return (
        <ModalEditClinicMemo info={info} title={'CS메모'} onClose={close} />
      );
    });
  };

  const handleClickDeactivateClinic = async (item: any) => {
    await imperativeModal.open((close) => {
      return (
        <ModalDeactivate info={item} title={'거래처 해지'} onClose={close} />
      );
    });
  };

  const restoreClinic = async (id: string) => {
    app.loadingToggle(true);
    try {
      const response: any = await app.$clinicRepo.clinic.restore(id);
      if (response?.code > 400) {
        throw new Error(response);
      }
    } catch (e: any) {
      snackbar.alert(e.description);
      app.loadingToggle(false);
      close();
    }
  };

  const handleClickRestoreClinic = async (item: any) => {
    runInAction(() => {
      app.$modal
        .basic({
          body: t('CLINIC_RESTORE'),
          buttons: [
            { text: 'CANCEL', class: 'btn-gray' },
            { text: 'CONFIRM', class: 'btn-primary' },
          ],
        })
        .then((data: any) => {
          if (data === 1) {
            restoreClinic(item.id).then(() => {
              app.loadingToggle(false);
              runInAction(() => {
                app.$modal.basic({
                  body: t('CLINIC_RESTORE_COMPLETED'),
                  buttons: [{ text: 'CONFIRM', class: 'btn-primary' }],
                });
                item.status = 'active';
              });
            });
          }
        });
    });
  };

  const schema = useMemo(
    () => ({
      columns: [
        {
          id: 'id',
          name: 'ID',
          value: (item: any) => (
            <Link to={`/clinics/${item.id}`}>{item.id}</Link>
          ),
        },
        {
          id: 'action',
          name: 'ACTION',
          value: (item: any) => {
            return (
              <Tooltip
                title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}
              >
                <span>
                  {!(item.status == null || item.status === '') ? (
                    item.status === 'active' ? (
                      <Button
                        size="xs"
                        styled="outline"
                        color={'black'}
                        onClick={() => handleClickDeactivateClinic(item)}
                        disabled={app.$me.role === 'manager'}
                        sx={{ width: '53px' }}
                      >
                        해지하기
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleClickRestoreClinic(item)}
                        disabled={app.$me.role === 'manager'}
                        styled="fill"
                        size="xs"
                        color={'mix'}
                        sx={{
                          width: '53px',
                          background: `${theme.palette.bluegrey['700']} !important`,
                        }}
                      >
                        복구하기
                      </Button>
                    )
                  ) : (
                    <span style={{ color: 'red' }}>해지</span>
                  )}
                </span>
              </Tooltip>
            );
          },
        },
        {
          id: 'test',
          name: '계정유형',
          value: (item: any) => {
            return item.test ? '테스트 계정' : '병원 계정';
          },
        },
        {
          id: 'status',
          name: '계약상태',
          value: (item: any) => {
            return !(item.status == null || item.status === '') ? (
              item.status === 'active' ? (
                <span>사용중</span>
              ) : (
                <span style={{ color: 'red' }}>해지</span>
              )
            ) : (
              <span style={{ color: 'red' }}>해지</span>
            );
          },
        },
        {
          id: 'createdDate',
          name: '등록일',
          value: (item: any) => (
            <Box className={`date`}>
              {format(new Date(item.createdAt), 'yyyy-MM-dd')}
            </Box>
          ),
        },
        {
          id: 'name',
          name: '거래처명',
          value: (item: any) => (
            <Link to={`/clinics/${item.id}`}>{item.name}</Link>
          ),
        },
        {
          id: 'employerNo',
          name: '사업자번호',
          value: (item: any) => (
            <Box>{item.employerNo ? item.employerNo : '-'}</Box>
          ),
        },
        {
          id: 'oldName',
          name: '구 거래처명',
          value: (item: any) => <Box>{item.oldName ? item.oldName : '-'}</Box>,
        },
        {
          id: 'clinicTel',
          name: '대표번호',
          value: (item: any) => (
            <Box className={`phone-number`}>
              {item.clinicTel ? item.clinicTel : '-'}
            </Box>
          ),
        },
        {
          id: 'contactName',
          name: '담당자',
          value: (item: any) => {
            const name = item.contactManager ? item.contactManager : '-';
            return <Box>{maskName(name)}</Box>;
          },
        },
        {
          id: 'contactTel',
          name: '전화번호',
          value: (item: any) => {
            const phoneNumber = item.contactTel ? item.contactTel : '-';
            return (
              <Box className={`phone-number`}>
                {maskPhoneNumber(phoneNumber)}
              </Box>
            );
          },
        },
        {
          id: 'contractDate',
          name: '최초 계약일',
          value: (item: any) => (
            <Box className={`contract-date`}>
              {item.contractDate
                ? format(new Date(item.contractDate), 'yyyy-MM-dd')
                : '-'}
            </Box>
          ),
        },
        {
          id: 'agency',
          name: '계약 대행사',
          value: (item: any) => {
            return (
              agencies.find(
                (agent: any) => agent.value === item?.additional?.agency
              )?.key || '없음'
            );
          },
        },
        {
          id: 'educationAgency',
          name: '교육 대행사',
          value: (item: any) => {
            return (
              agencies.find(
                (agent: any) =>
                  agent.value === item?.additional?.educationAgency
              )?.key || '없음'
            );
          },
        },
        {
          id: 'centrexAccounts',
          name: '센트릭스',
          value: (item: any) => {
            return (
              <Box className="centrex-wrapper">
                {item?.centrexAccounts && item?.centrexAccounts?.length > 0
                  ? item.centrexAccounts.map((accountItem: any, id: number) => (
                      <span key={id}>
                        {carrierType.getName(accountItem?.carrier)}
                      </span>
                    ))
                  : '-'}
              </Box>
            );
          },
        },
        {
          id: 'crmManagementPlan',
          name: (
            <span>
              CRM 운영비
              <br />
              (비용 / 계정)
            </span>
          ),
          value: (item: any) => {
            return (
              <>
                <span>
                  {item.billingPlan?.crmManagementPlanPrice &&
                  !isNaN(Number(item.billingPlan?.crmManagementPlanPrice))
                    ? toThousandCommas(item.billingPlan.crmManagementPlanPrice)
                    : '-'}
                </span>{' '}
                /{' '}
                <span>
                  {item.billingPlan?.crmManagementPlanLimit &&
                  !isNaN(Number(item.billingPlan?.crmManagementPlanLimit))
                    ? toThousandCommas(item.billingPlan.crmManagementPlanLimit)
                    : '-'}
                </span>
              </>
            );
          },
        },
        {
          id: 'penchartPlan',
          name: (
            <span>
              펜차트 관리비
              <br />
              (비용 / GB)
            </span>
          ),
          value: (item: any) => {
            return (
              <>
                <span>
                  {item.billingPlan?.penchartPlanPrice &&
                  !isNaN(Number(item.billingPlan?.penchartPlanPrice))
                    ? toThousandCommas(item.billingPlan.penchartPlanPrice)
                    : '-'}
                </span>{' '}
                /{' '}
                <span>
                  {item.billingPlan?.penchartPlanLimit &&
                  !isNaN(Number(item.billingPlan?.penchartPlanLimit))
                    ? toThousandCommas(item.billingPlan.penchartPlanLimit)
                    : '-'}
                </span>
              </>
            );
          },
        },
        {
          id: 'signCertPlan',
          name: (
            <span>
              전자서명
              <br />
              (비용 / 계정)
            </span>
          ),
          value: (item: any) => {
            return (
              <>
                <span>
                  {item.billingPlan?.signCertPlanPrice &&
                  !isNaN(Number(item.billingPlan?.signCertPlanPrice))
                    ? toThousandCommas(item.billingPlan.signCertPlanPrice)
                    : '-'}
                </span>{' '}
                /{' '}
                <span>
                  {item.billingPlan?.signCertPlanLimit &&
                  !isNaN(Number(item.billingPlan?.signCertPlanLimit))
                    ? toThousandCommas(item.billingPlan.signCertPlanLimit)
                    : '-'}
                </span>
              </>
            );
          },
        },
        {
          id: 'number080Plan',
          name: (
            <span>
              080번호
              <br />
              (비용)
            </span>
          ),
          value: (item: any) => {
            return (
              <>
                <span>
                  {item.billingPlan?.number080PlanPrice &&
                  !isNaN(Number(item.billingPlan?.number080PlanPrice))
                    ? toThousandCommas(item.billingPlan.number080PlanPrice)
                    : '-'}
                </span>
              </>
            );
          },
        },
        {
          id: 'insurancePlan',
          name: (
            <span>
              보험청구
              <br />
              (연동 / 비용 / 계정)
            </span>
          ),
          value: (item: any) => {
            return (
              <>
                <span>{item.isUberSync === true ? '연동' : '미연동'}</span> /{' '}
                <span>
                  {item.billingPlan?.insurancePlanPrice &&
                  !isNaN(Number(item.billingPlan?.insurancePlanPrice))
                    ? toThousandCommas(item.billingPlan.insurancePlanPrice)
                    : '-'}
                </span>{' '}
                /{' '}
                <span>
                  {item.billingPlan?.insurancePlanLimit &&
                  !isNaN(Number(item.billingPlan?.insurancePlanLimit))
                    ? toThousandCommas(item.billingPlan.insurancePlanLimit)
                    : '-'}
                </span>
              </>
            );
          },
        },
        {
          id: 'totalPlan',
          name: (
            <span>
              월 관리비 총액
              <br />
              (Vat별도)
            </span>
          ),
          value: (item: any) => {
            return (
              <>
                <span>{toThousandCommas(item.totalManagementPrice)}</span>
              </>
            );
          },
        },
        {
          id: 'memo',
          name: '메모',
          value: (item: any) => {
            let memo = [];
            try {
              memo =
                item?.memo !== undefined &&
                item?.memo !== '' &&
                item?.memo !== null &&
                item?.memo !== '{}' &&
                item?.memo !== '[]'
                  ? JSON.parse(item.memo || null) || []
                  : [];
            } catch (error) {
              console.error('error parsing: ', error);
              memo = [];
            }
            return (
              <Box className={`memo`}>
                <div>
                  {memo.map((memoItem: any, idx: number) => {
                    return (
                      <div
                        onClick={() =>
                          handleClickMemo(item.memo, idx, item.id, item)
                        }
                        className="td-underline"
                        key={idx}
                        style={{ cursor: 'pointer' }}
                      >
                        {memoItem.content} ({memoItem.timestamp})
                      </div>
                    );
                  })}
                </div>
                <Box className="memo-btn">
                  <Button
                    size="xxs"
                    className="btn btn-info"
                    onClick={() =>
                      handleClickMemo(item.memo, -1, item.id, item)
                    }
                  >
                    메모 추가
                  </Button>
                </Box>
              </Box>
            );
          },
        },
      ],
    }),
    []
  );

  return (
    <DataTable
      data={items}
      schema={schema}
      sorts={[orderBy]}
      resizable
      placeholder={'조회 결과가 없습니다.'}
    />
  );
};
