import { useMemo } from 'react';
import { DataTable } from '~/components/commons/DataTable/DataTable';
import { Box } from './DataTable.styled';

type UsersDataTableProps = {
  data: any;
  orderBy: { id: any; value: any };
  pagination: {
    page: number;
    limit: number;
  };
  onChangePagination: (pg: any) => void;
};

export const UsersDataTable = (props: UsersDataTableProps) => {
  const { data, orderBy } = props;
  const items = useMemo(() => data?.data ?? [], [data]);

  const schema = useMemo(
    () => ({
      columns: [
        {
          id: 'no',
          name: 'NO',
          value: (item: any) => item.id || '-',
        },
        {
          id: 'status',
          name: '상태',
          value: (item: any) => {
            return !(item.status == null || item.status === '') ? (
              item.status === 'active' ? (
                <span>사용중</span>
              ) : item.status === 'inactive' ? (
                <span style={{ color: 'red' }}>미사용</span>
              ) : item.status === 'deleted' ? (
                <span style={{ color: 'red' }}>삭제</span>
              ) : (
                <span>-</span>
              )
            ) : (
              <span>-</span>
            );
          },
        },
        {
          id: 'id',
          name: 'ID',
          value: (item: any) => (
            <Box className="id-wrapper">{item.email || '-'}</Box>
          ),
        },
        {
          id: 'name',
          name: '이름',
          value: (item: any) => item.name || '-',
        },
        {
          id: 'position',
          name: '직책',
          value: (item: any) => item.position || '-',
        },
        {
          id: 'certification',
          name: '전자서명 등록 여부',
          value: (item: any) =>
            item.certification === null ? '미등록' : '등록',
        },
      ],
    }),
    []
  );

  return (
    <>
      <DataTable data={items} schema={schema} sorts={[orderBy]} resizable />
    </>
  );
};
